@use "shared/dist/styles/variables.scss" as v;

$card-sides-gap-sm: v.$spacing-sm;
$card-sides-gap-md: v.$spacing-md;
.managed-funds-withdraw-modal {
  .card {
    padding: v.$spacing-md $card-sides-gap-sm;

    @include v.breakpoint(v.$screen-md) {
      padding: $card-sides-gap-md;
    }
  }

  .form-radio--checked + label {
    color: v.$color-dusk;
  }

  .next-step-arrow {
    padding-bottom: v.$spacing-xl;
    padding-top: v.$spacing-lg;

    @include v.breakpoint(v.$screen-md) {
      padding-top: v.$spacing-xl;
      padding-bottom: v.$spacing-xl;
    }
  }
}