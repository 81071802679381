@use "shared/dist/styles/variables.scss" as v;
@use "./MFWithdrawFundsModal.scss" as m;

.mf-withdraw-amount {
  hr {
    margin-left: -#{m.$card-sides-gap-sm};
    margin-right: -#{m.$card-sides-gap-sm};

    @include v.breakpoint(v.$screen-md) {
      margin-left: -#{m.$card-sides-gap-md};
      margin-right: -#{m.$card-sides-gap-md};
    }
  }

  .fund-selector {
    background-color: v.$background-color;
    border: 1px solid v.$color-seafoam;
    border-top: none;
    border-bottom-right-radius: v.$border-radius-medium;
    border-bottom-left-radius: v.$border-radius-medium;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    margin: 0 v.$spacing-md;
    padding: 0;

    .fund-item {
      border-bottom: 1px solid v.$color-border;
      display: flex;
      flex-direction: column;
      list-style: none;
      row-gap: v.$spacing-xs;
      padding: v.$spacing-sm v.$spacing-md;

      &:not(:last-of-type) {
        padding-bottom: v.$spacing-md;
      }

      @include v.breakpoint(v.$screen-md) {
        border-bottom: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &:hover {
          background-color: rgba(v.$color-sky, 0.25)
        }

        .input-wrapper__wrapper {
          width: 240px;
        }
      }
    }
  }
}