@use "shared/dist/styles/variables.scss" as v;

.mf-withdraw-review {
  .withdraw-type {
    @include v.breakpoint(v.$screen-md) {
      padding-right: v.$spacing-xs;
    }
  }

  .wallet-icon {
    height: 25px;
    width: 25px;

    @include v.breakpoint(v.$screen-md) {
      height: 35px;
      width: 35px;
    }
  }

  .funds-list {
    margin: 0;
    padding-left: v.$spacing-sm;

    @include v.breakpoint(v.$screen-md) {
      padding-left: v.$spacing-2xl;
    }

    li {
      &:not(:last-of-type) {
        border-bottom: 1px solid v.$color-border;
      }

      @include v.breakpoint(v.$screen-md) {
        padding-left: v.$spacing-xs;
        padding-right: v.$spacing-xs;
      }
    }
  }

  .bank-account {
    color: v.$color-midnight;
    font-size: v.$font-size-sm;
    font-weight: 600;
  }
}