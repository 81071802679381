@use "shared/dist/styles/variables.scss" as v;

.mf-withdraw-form {
  label {
    font-weight: 600;
  }

  .currency__total {
    display: inline-flex;
  }

  .input-group {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    padding: 0;
    z-index: 1;

    > *,
    .partial-withdraw-amount input {
      background: transparent;
      border: none;
    }

    .partial-withdraw-amount {
      flex: 1 1 auto;
    }

    .input-amount__wrapper {
      border: none;
      background-color: transparent;
      height: 100%;
    }

    .switch-label {
      color: v.$color-smoke;
    }
  
    .switch-funds-selector {
      flex: 0 0 auto;
      font-weight: 400;
      padding: 1px;
    }
  }

  .input-amount--dollar-sign {
    &.input-group,
    &.input-amount__wrapper {
      background-color: v.$color-white;
      border: 1px solid v.$color-seafoam;
      border-radius: v.$border-radius-large;
      color: v.$color-midnight;
      height: 40px;

      &.input-amount--disabled {
        background-color: v.$background-color;
        border-color: v.$color-border;
        color: rgba(v.$color-midnight, 0.5);
      }

      &.input-amount--error {
        border-color: v.$color-puriri;

        .input-amount__element {
          color: v.$color-puriri;
        }
      }

      .input-amount__element,
      .input-amount__label {
        height: 100%;
      }

      .input-amount__label {
        background-color: rgba(v.$color-midnight, 0.05);
        color: rgba(v.$color-midnight, 0.5);
      }
    }
  }
}