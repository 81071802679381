@use "shared/dist/styles/variables" as v;

.next-step-arrow {
  background-image: url("../../../assets/images/next-step-dashed-line.svg");
  background-position: bottom center;
  background-repeat: repeat-y;
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    background-color: v.$background-color;
  }
}